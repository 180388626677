import Resizer from "react-image-file-resizer";
import heic2any from "heic2any";
import EXIF from "exif-js";

export const handleConvert = (
  file,
  convertTo,
  width,
  height,
  compress,
  removeExif,
  setLoading,
  setMsg,
  setState
) => {
  if (file) {
    setLoading(true);

    // Convert HEIC file if needed
    const convertFile =
      file.type === "image/heic" || file.type === "image/heif"
        ? heic2any({ blob: file, toType: convertTo.toUpperCase() })
        : Promise.resolve(file);

    convertFile
      .then((convertedBlob) => {
        Resizer.imageFileResizer(
          convertedBlob,
          width, // largeurs souhaitée
          height, // hauteur souhaitée
          convertTo.toUpperCase(),
          compress ? 70 : 100,
          0,
          (resizedBlob) => {
            const blobUrl = URL.createObjectURL(resizedBlob);
            const originalName = file.name.split(".").slice(0, -1).join(".");
            const newFileName = `${originalName}.${convertTo}`;

            const link = document.createElement("a");
            link.href = blobUrl;
            link.download = newFileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(blobUrl);

            setLoading(false);
          },
          "blob",
          removeExif
        );
      })
      .catch((error) => {
        console.error("Error converting file:", error);
        setMsg("Error converting file");
        setState("error");
        setLoading(false);
      });
  }
};

export const getImageDimensions = (url, setImageDimensions) => {
  const img = document.createElement("img");
  img.onload = () => {
    setImageDimensions({
      width: img.width,
      height: img.height,
    });
  };
  img.src = url;
};

export const getLocaImage = (file, setLocation) => {
  // Extraire les données EXIF, y compris la localisation GPS
  EXIF.getData(file, function () {
    const gpsLat = EXIF.getTag(this, "GPSLatitude");
    const gpsLong = EXIF.getTag(this, "GPSLongitude");
    const gpsLatRef = EXIF.getTag(this, "GPSLatitudeRef");
    const gpsLongRef = EXIF.getTag(this, "GPSLongitudeRef");

    if (gpsLat && gpsLong) {
      const lat = convertDMSToDD(gpsLat, gpsLatRef);
      const long = convertDMSToDD(gpsLong, gpsLongRef);
      setLocation({ lat, long });
    } else {
      console.log("No GPS data found");
    }
  });
};

const convertDMSToDD = (dms, ref) => {
  const degrees = dms[0] + dms[1] / 60 + dms[2] / 3600;
  return ref === "S" || ref === "W" ? -degrees : degrees;
};

export const getDevice = (file, setDevice) => {
  EXIF.getData(file, function () {
    setDevice(EXIF.getTag(this, "Make"));
  });
};

export const getAllExifData = (file, setExifData) => {
  EXIF.getData(file, function () {
    const allExifData = EXIF.getAllTags(this);
    setExifData(allExifData);
  });
};
