import React, { useCallback, useEffect, useRef, useState } from "react";
import "./dockBar.css";
import jsonp from "jsonp";
import { listApp } from "../../function/var";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { evaluate } from "mathjs";

import Logo from "../../../assets/images/logo-active.png";

import { RiArrowRightUpLine, RiSearch2Line } from "react-icons/ri";

import IconFirefox from "../../../assets/images/icons/iconFirefox.png";
import { app } from "../../firebase/firebase";

function DockBar() {
  const inputRef = useRef(null);
  const navDockRef = useRef(null);

  const db = app.firestore();

  const [result, setResult] = useState(false);
  const [active, setActive] = useState(false);
  const [search, setSearch] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [appSearch, setAppSearch] = useState(null);
  const [calc, setCalc] = useState();

  const [image, setImage] = useState(Logo);

  const navigate = useNavigate();
  const location = useLocation();

  const handleCalc = useCallback((text) => {
    if (text.length > 2) {
      try {
        const temp = evaluate(text);
        if (JSON.stringify(temp).charAt(0) !== "{")
          setCalc(JSON.stringify(temp));
      } catch (error) {
        setCalc();
      }
    } else {
      setCalc();
    }
  }, []);

  const handleClickApp = useCallback(
    (link) => {
      navigate(link);
      handleClose();
    },
    [navigate] // 'navigate' est une dépendance ici
  );

  useEffect(() => {
    const uid = sessionStorage.getItem("uid");
    db.collection("users")
      .doc(uid)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setImage(doc.data().photoURL);
          doc.data().bg &&
            document.documentElement.style.setProperty(
              "--bg-dock",
              "url(" + doc.data().bg + ")"
            );
          doc.data().color &&
            document.documentElement.style.setProperty(
              "--c-default",
              "#" + doc.data().color
            );
        } else {
          window.location.href = "https://auth.loukaaa.com";
        }
      });

    document.querySelector("body").style.overflow = "hidden";

    location.pathname === "/dock"
      ? document.getElementById("navDock").classList.remove("path")
      : document.getElementById("navDock").classList.add("path");

    const handleKeyDown = (event) => {
      const totalItems = suggestions.length + (appSearch || calc ? 1 : 0); // Inclure calc ou appSearch
      if (event.key === "ArrowUp") {
        event.preventDefault();
        setActiveIndex((prevIndex) =>
          prevIndex === 0 ? totalItems - 1 : prevIndex - 1
        );
      } else if (event.key === "ArrowDown") {
        event.preventDefault();
        setActiveIndex((prevIndex) =>
          prevIndex === totalItems - 1 ? 0 : prevIndex + 1
        );
      } else if (event.key === "Enter" && active) {
        event.preventDefault();
        if (activeIndex === 0 && (calc || appSearch)) {
          calc
            ? navigator.clipboard.writeText(calc)
            : handleClickApp(appSearch.link);
        } else {
          const suggestionIndex =
            calc || appSearch ? activeIndex - 1 : activeIndex;
          window.location.href = `https://www.google.com/search?q=${suggestions[suggestionIndex]}`;
        }
        handleClose();
      } else {
        const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
        if (
          (isMac && event.metaKey && event.key === "k") ||
          (!isMac && event.ctrlKey && event.key === "k")
        )
          handleActive(event);
        else if (event.key === "Escape") handleClose();

        if (
          (isMac && event.metaKey && event.key === "j") ||
          (!isMac && event.ctrlKey && event.key === "j")
        )
          if (location.pathname !== "/dock") {
            navigate("/dock");
            handleClose();
          }
      }
    };

    const handleClickOutside = (event) => {
      if (navDockRef.current && !navDockRef.current.contains(event.target))
        handleClose();
    };

    window.addEventListener("keydown", handleKeyDown);
    document.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("click", handleClickOutside);
    };
  }, [
    activeIndex,
    appSearch,
    db,
    location.pathname,
    navigate,
    suggestions,
    handleCalc,
    handleClickApp,
    calc,
  ]);

  const handleActive = (event) => {
    event.preventDefault();
    document.getElementById("navDock").classList.toggle("active");
    inputRef.current.focus();
    setActive((a) => !a);
  };

  const handleClose = () => {
    document.getElementById("navDock").classList.remove("active");
    inputRef.current.blur();
    setActive(false);
  };

  const handleSearch = (text) => {
    handleCalc(text); // Appel de la fonction pour évaluer si c'est une expression calculable
    setSearch(text);
    setResult(text !== "");

    text !== ""
      ? document.getElementById("navDock").classList.add("result")
      : document.getElementById("navDock").classList.remove("result");

    var appIndex = listApp.findIndex(
      (item) => item.name.toLocaleLowerCase() === text.toLocaleLowerCase()
    );
    const appFound = appIndex >= 0 ? listApp[appIndex] : null;
    setAppSearch(appFound);

    if (calc) {
      setActiveIndex(0); // Sélectionner la calculatrice en premier si une expression est valide
    } else if (appFound) {
      setActiveIndex(0); // Sinon, sélectionner l'application trouvée
    } else {
      setActiveIndex(-1); // Réinitialiser si aucune application ni calcul n'est détecté
    }

    if (text.length > 2 && !calc) {
      fetchSuggestions(text);
    } else {
      setSuggestions([text]);
    }
  };

  const fetchSuggestions = async (query) => {
    var temp = [];
    const url = `https://suggestqueries.google.com/complete/search?client=firefox&q=${query}`;
    jsonp(url, null, (err, data) => {
      if (err) {
        console.error("Error fetching suggestions:", err);
      } else {
        if (data[1][0] === query) data[1].shift();
        temp = data[1].slice(0, 3);
        temp.unshift(query);
        setSuggestions(temp);
      }
    });
  };

  const searchAppNameWithLink = (link) => {
    var index = listApp.findIndex((item) => "/dock/" + item.link === link);
    return index;
  };

  return (
    <div
      className="DockBar"
      id="navDock"
      ref={navDockRef}
      onClick={(e) => !active && handleActive(e)}
    >
      <div className="top">
        {location.pathname === "/dock" ? (
          <img className="logo" src={Logo} alt="logo black" />
        ) : (
          <Link to="/dock" className="logo h4">
            {searchAppNameWithLink(location.pathname) >= 0
              ? listApp[searchAppNameWithLink(location.pathname)].name
              : "404"}
          </Link>
        )}
        <div className="search">
          <RiSearch2Line className="icon" />
          <input
            type="text"
            placeholder="Recherche"
            ref={inputRef}
            value={search}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
        <img className="account" src={image} alt="logo user" />
      </div>
      {active && result && (
        <>
          {(calc || appSearch) && ( // Prioriser calc d'abord
            <div className="result app">
              <div
                className={`item ${activeIndex === 0 ? "active" : ""}`}
                onClick={() =>
                  calc
                    ? navigator.clipboard.writeText(calc)
                    : handleClickApp(appSearch.link)
                }
              >
                <div className="content">
                  <div
                    className={calc ? "dock-icon-calc" : appSearch.src}
                  ></div>
                  <p>{calc ? calc : appSearch.name}</p>
                </div>
                <div className="button">{calc ? "Copier" : "Ouvrir"}</div>
              </div>
            </div>
          )}
          <div className="result">
            {suggestions.map((s, index) => (
              <a
                href={"https://www.google.com/search?q=" + s}
                key={index}
                className={`item ${
                  index === (calc || appSearch ? activeIndex - 1 : activeIndex)
                    ? "active"
                    : ""
                }`}
              >
                <div>
                  <img src={IconFirefox} alt="icon" />
                  <div>
                    <p>{s}</p>
                  </div>
                </div>
                <RiArrowRightUpLine className="icon" />
              </a>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default DockBar;
