import React from "react";
import "./dock.css";

// icons
import { IoImageOutline } from "react-icons/io5";

function NavImage({ setValue, value, placeholder }) {
  return (
    <label htmlFor="file" className="nav-input nav-image">
      <div className="url-input">
        <IoImageOutline className="icon" />
        <label htmlFor="file">{placeholder}</label>
        <input
          type="file"
          id="file"
          value={value}
          onChange={(e) => setValue(e)}
        />
      </div>
    </label>
  );
}

export default NavImage;
