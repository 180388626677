import React, { useState } from "react";
import EXIF from "exif-js";
import heic2any from "heic2any";
import "./image.css";
import ButtonComponent from "../../../components/button";
import Notification from "../../../components/notification";
import SelectInput from "../../../components/input/selectInput";
import Toggle from "../../../components/input/toggle";
import NavImage from "../navImage";
import {
  getDevice,
  getImageDimensions,
  getLocaImage,
  handleConvert,
} from "../../../components/function/image";

function Image() {
  const [msg, setMsg] = useState(null);
  const [state, setState] = useState(null);
  const [loading, setLoading] = useState(false);

  const [file, setFile] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [imageFormat, setImageFormat] = useState("");
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [location, setLocation] = useState(null);
  const [device, setDevice] = useState(null);

  const [convertTo, setConvertTo] = useState("jpeg");
  const [removeExif, setRemoveExif] = useState(false);
  const [compress, setCompress] = useState(false);

  const handleImageChange = async (event) => {
    setDevice(null);
    setLocation(null);

    const file = event.target.files[0];
    if (file) {
      setFile(file);

      if (file.type === "image/heic" || file.type === "image/heif") {
        console.log("HEIC file detected");
        try {
          // Convert HEIC file to JPEG
          const convertedBlob = await heic2any({
            blob: file,
            toType: "image/jpeg",
          });

          // Create an object URL for the converted image
          const blobUrl = URL.createObjectURL(convertedBlob);
          setImageUrl(blobUrl);
          getImageDimensions(blobUrl, setImageDimensions);

          // Clean up the object URL when the component is unmounted
          return () => URL.revokeObjectURL(blobUrl);
        } catch (error) {
          console.error("Error converting HEIC file:", error);
        }
      } else {
        const url = URL.createObjectURL(file);
        getImageDimensions(url, setImageDimensions);
        setImageUrl(url);
      }

      const format = file.type.split("/")[1];
      setImageFormat(format);
      setConvertTo(format === "jpeg" ? "png" : "jpeg"); // Set a default conversion target

      // Get location from EXIF data
      getLocaImage(file, setLocation);
      getDevice(file, setDevice);
    }
  };

  const handleConversionChange = (event) => {
    setConvertTo(event.target.value);
  };

  return (
    <div className="Image PageDock">
      <Notification
        msg={msg}
        state={state}
        setState={(value) => setState(value)}
        setMsg={(value) => setMsg(value)}
      />
      <div className="wrapper">
        <NavImage
          placeholder="Choisir une image"
          setValue={(e) => handleImageChange(e)}
        />
        {imageUrl && (
          <div className="flex-window">
            <div className="window">
              <div className="section">
                <h4>Édition</h4>
                <p>Convertir, compresser et supprimer les données EXIF.</p>
              </div>
              <div className="section editing">
                <img src={imageUrl} alt="" />
                <div className="settings">
                  <SelectInput
                    value={convertTo}
                    onChange={handleConversionChange}
                  >
                    <option value="jpeg">JPEG</option>
                    <option value="png">PNG</option>
                    <option value="webp">WEBP</option>
                  </SelectInput>
                  <Toggle
                    label="Supprimer les exifs"
                    value={removeExif}
                    onChange={(e) => setRemoveExif(e.target.checked)}
                  />
                  <Toggle
                    label="Compresser l'image"
                    value={compress}
                    onChange={(e) => setCompress(e.target.checked)}
                  />
                </div>
              </div>
              <div className="section">
                <ButtonComponent
                  text="Télécharger"
                  onClick={() =>
                    handleConvert(
                      file,
                      convertTo,
                      imageDimensions?.width,
                      imageDimensions?.height,
                      compress,
                      removeExif,
                      setLoading,
                      setMsg,
                      setState
                    )
                  }
                  loading={loading}
                />
              </div>
            </div>
            <div className="window">
              <div className="section">
                <h4>Information</h4>
                <p>Retrouvez ici toutes les informations de votre image.</p>
              </div>
              <div className="section information">
                <div className="item">
                  <p>Nom</p>
                  <span>{file ? file.name : ""}</span>
                </div>
                <div className="item">
                  <p>Format</p>
                  <span>{imageFormat.toUpperCase()}</span>
                </div>
                <div className="item">
                  <p>Dimensions</p>
                  <span>
                    {imageDimensions.width + " x " + imageDimensions.height}
                  </span>
                </div>
                <div className="item">
                  <p>Taille</p>
                  <span>
                    {file ? (file.size / 1024 / 1024).toFixed(2) + " Mo" : ""}
                  </span>
                </div>
                <div className="item">
                  <p>Créé le</p>
                  <span>
                    {file
                      ? new Date(file.lastModified).toLocaleDateString()
                      : ""}
                  </span>
                </div>
              </div>
              {device && (
                <div className="section device">
                  <div className="header">
                    <p className="sub-title">Appareil</p>
                    <span>
                      Les informations de l'appareil qui a pris la photo.
                    </span>
                  </div>
                  <div className="exif">
                    <div className="item">
                      <p>Marque</p>
                      <span>{EXIF.getTag(file, "Make")}</span>
                    </div>
                    <div className="item">
                      <p>Modèle</p>
                      <span>{EXIF.getTag(file, "Model")}</span>
                    </div>
                    <div className="item">
                      <p>ISO</p>
                      <span>{EXIF.getTag(file, "ISOSpeedRatings")}</span>
                    </div>
                    {EXIF.getTag(file, "ExposureTime") !== undefined && (
                      <div className="item">
                        <p>Vitesse d'obturation</p>
                        <span>
                          {JSON.stringify(EXIF.getTag(file, "ExposureTime"))}
                        </span>
                      </div>
                    )}
                    {EXIF.getTag(file, "FNumber") !== undefined && (
                      <div className="item">
                        <p>Ouverture</p>
                        <span>
                          {JSON.stringify(EXIF.getTag(file, "FNumber"))}
                        </span>
                      </div>
                    )}
                    {EXIF.getTag(file, "FocalLength") !== undefined && (
                      <div className="item">
                        <p>Longueur focale</p>
                        <span>
                          {JSON.stringify(EXIF.getTag(file, "FocalLength"))}
                        </span>
                      </div>
                    )}
                    {EXIF.getTag(file, "DateTimeOriginal") !== undefined && (
                      <div className="item">
                        <p>Date de prise de vue</p>
                        <span>
                          {JSON.stringify(
                            EXIF.getTag(file, "DateTimeOriginal")
                          )}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {location && (
                <div className="section maps">
                  <div className="header">
                    <p className="sub-title">Localisation</p>
                    <span>
                      Localisation de ou la photo a été prise sur la carte.
                    </span>
                  </div>
                  <div className="map">
                    <iframe
                      title="Google Maps Location"
                      width="600"
                      height="450"
                      style={{ border: 0 }}
                      loading="lazy"
                      allowFullScreen
                      src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyCkseQVFaJBIK2IC0vSsN4psOtznknabf0&q=${location.lat},${location.long}&zoom=14`}
                    ></iframe>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Image;
