import React from "react";
import "./toggle.css";

function Toggle({ value, onChange, label }) {
  const ref = React.useRef();

  const [checked, setChecked] = React.useState(value);

  React.useEffect(() => {
    setChecked(value);
  }, [value]);

  return (
    <div className="Togggle">
      <label className="ctn-slider">
        <input
          ref={ref}
          type="checkbox"
          onChange={(e) => {
            setChecked(e.target.checked);
            onChange(e);
          }}
          checked={checked}
        />
        <span className="slider round"></span>
      </label>
      {label && <label onClick={() => ref.current.click()}>{label}</label>}
    </div>
  );
}

export default Toggle;
