import React, { useEffect, useState } from "react";
import "./notificationSettings.css";
import ButtonComponent from "../../../components/button";
import Toggle from "../../../components/input/toggle";
import { useNavigate } from "react-router-dom";
import { app } from "../../../components/firebase/firebase";
import Notification from "../../../components/notification";

function NotificationSettings() {
  const navigate = useNavigate();

  const db = app.firestore();
  const uid = sessionStorage.getItem("uid");

  const [email, setEmail] = useState("");
  const [actualite, setActualite] = useState(false);
  const [promotion, setPromotion] = useState(false);

  const [reset, setReset] = useState(false);

  // notification useState
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const [state, setState] = useState(null);

  useEffect(() => {
    db.collection("users")
      .doc(uid)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setEmail(doc.data().email);
          db.collection("users")
            .doc(uid)
            .get()
            .then((doc) => {
              if (doc.exists) {
                const data = doc.data();
                setActualite(data?.actualite);
                setPromotion(data?.promotion);
              }
            });
        }
      });
  }, [db, reset, uid]);

  const handleCancel = () => {
    navigate("/dock");
  };

  const handleSave = () => {
    setLoading(true);
    db.collection("users")
      .doc(uid)
      .update({
        actualite: actualite,
        promotion: promotion,
      })
      .then(() => {
        setMsg("Les modifications ont bien été enregistrées");
        setState(true);
        setLoading(false);
      })
      .catch(() => {
        setMsg("Une erreur s'est produite");
        setState(false);
        setLoading(false);
      });
  };
  return (
    <>
      <Notification
        msg={msg}
        state={state}
        setState={(value) => setState(value)}
        setMsg={(value) => setMsg(value)}
      />
      <div className="NotificationSettings window">
        <div className="section">
          <h4>Notification</h4>
          <p>
            Choisisez les types de notifications que vous souhaitez recevoir.
          </p>
        </div>
        <div className="section">
          <div className="header">
            <p className="sub-title">Email</p>
            <span>
              Vous recevrez des notifications par email à l'adresse suivante.
            </span>
          </div>
          <div className="list">
            <div className="item">
              <p className="email">{email}</p>
            </div>
            <div className="item">
              <p>Actualités</p>
              <Toggle
                value={actualite}
                onChange={(e) => setActualite(e.target.checked)}
              />
            </div>
            <div className="item">
              <p>Promotions, offres spéciales</p>
              <Toggle
                value={promotion}
                onChange={(e) => setPromotion(e.target.checked)}
              />
            </div>
          </div>
        </div>
        <div className="section buttons">
          <ButtonComponent
            skin="secondy"
            text="Reset"
            onClick={() => setReset(!reset)}
          />
          <div className="ctn-btn">
            <ButtonComponent
              skin="secondy"
              text="Annuler"
              onClick={() => handleCancel()}
            />
            <ButtonComponent
              text="Sauvegarder"
              onClick={() => handleSave()}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default NotificationSettings;
