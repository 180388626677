import React, { useEffect, useState } from "react";
import "./subscriptionSettings.css";
import ButtonComponent from "../../../components/button";
import { useNavigate } from "react-router-dom";

function SubscriptionSettings() {
  const navigate = useNavigate();

  const [price, setPrice] = useState();
  const [DateBuy, setDateBuy] = useState("");

  useEffect(() => {
    setPrice(5.99);
    setDateBuy("20 juillet 2024");
  }, []);

  const handleCancel = () => {
    navigate("/dock");
  };
  return (
    <div className="SubscriptionSettings window">
      <div className="section">
        <h4>Abonnement</h4>
        <p>
          Retrouvez ici toutes les informations concernant votre abonnement.
        </p>
      </div>
      <div className="section">
        <div className="header">
          <p className="sub-title">Pro</p>
          <span>
            Vous avez un abonnement Pro, vous avez accès à toutes les
            fonctionnalités.
          </span>
        </div>
      </div>
      <div className="section">
        <div className="list">
          <div className="item">
            <span>Prochaine facturation</span>
            <p>{DateBuy}</p>
          </div>
          <div className="item">
            <span>Prix</span>
            <p>{price}€</p>
          </div>
        </div>
      </div>
      <div className="section buttons">
        <ButtonComponent
          skin="secondy"
          text="Annuler"
          onClick={() => handleCancel()}
        />
        <div className="button-red">
          <ButtonComponent text="Résilier" />
        </div>
      </div>
    </div>
  );
}

export default SubscriptionSettings;
