import React, { useState, useEffect } from "react";
import "./accountSettings.css";
import ButtonComponent from "../../../components/button";
import Resizer from "react-image-file-resizer";
import { useNavigate } from "react-router-dom";
import InputComponent from "../../../components/input";
import { app } from "../../../components/firebase/firebase";
import Notification from "../../../components/notification";
import { HiOutlineUpload } from "react-icons/hi";
import ImageDefault from "../../../assets/images/logouser.jpeg";

function AccountSettings() {
  const navigate = useNavigate();
  const db = app.firestore();
  const uid = sessionStorage.getItem("uid");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [imageUrl, setImageUrl] = useState(ImageDefault);
  const [file, setFile] = useState(null);

  const [reset, setReset] = useState(false);
  const [loading, setLoading] = useState(false);

  // notification useState
  const [msg, setMsg] = useState("");
  const [state, setState] = useState(null);

  useEffect(() => {
    db.collection("users")
      .doc(uid)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setFirstName(doc.data().firstName);
          setLastName(doc.data().lastName);
          setEmail(doc.data().email);
          setImageUrl(doc.data().photoURL);
        }
      });
  }, [db, reset, uid]);

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      await processImage(file);
    }
  };

  const processImage = (file) => {
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1024, // width
        1024, // height
        "JPEG", // format
        70, // quality
        0, // rotation
        (blob) => {
          const blobUrl = URL.createObjectURL(blob);
          setImageUrl(blobUrl);
          setFile(blob);
          resolve();
        },
        "blob",
        true // remove EXIF
      );
    });
  };

  const handleSave = () => {
    setLoading(true);
    if (file) {
      const storageRef = app.storage().ref();
      const fileRef = storageRef.child(`users/${uid}/profile.jpg`);
      fileRef.put(file).then(() => {
        fileRef
          .getDownloadURL()
          .then((url) => {
            db.collection("users").doc(uid).update({
              firstName: firstName,
              lastName: lastName,
              photoURL: url,
            });
          })
          .then(() => {
            setLoading(false);
            setMsg("Vos informations ont été mises à jour");
            setState(true);
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
            setMsg(
              "Une erreur est survenue lors de la mise à jour de vos informations"
            );
            setState(false);
          });
      });
    } else {
      db.collection("users")
        .doc(uid)
        .update({
          firstName: firstName,
          lastName: lastName,
        })
        .then(() => {
          setLoading(false);
          setMsg("Vos informations ont été mises à jour");
          setState(true);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          setMsg(
            "Une erreur est survenue lors de la mise à jour de vos informations"
          );
          setState(false);
        });
    }
  };

  const handleReset = () => {
    setReset(!reset);
  };

  const handleCancel = () => {
    navigate("/dock");
  };

  return (
    <>
      <Notification
        msg={msg}
        state={state}
        setState={(value) => setState(value)}
        setMsg={(value) => setMsg(value)}
      />
      <div className="AccountSettings window">
        <div className="section">
          <h4>Compte</h4>
          <p>Retrouvez ici toutes les informations concernant votre compte.</p>
        </div>
        <div className="section">
          <div className="header">
            <p className="sub-title">Photo de profile</p>
            <span>Donnez vous un visage, c'est toujours plus sympa.</span>
          </div>
          <div className="input-profile">
            <img src={imageUrl} alt="Profile" className="bg" />
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              id="bgSettings"
            />
            <label className="upload" htmlFor="bgSettings">
              <HiOutlineUpload className="icon" />
            </label>
          </div>
        </div>
        <div className="section">
          <div className="header">
            <p className="sub-title">Information</p>
            <span>
              Un nom et un prénom, c'est toujours plus simple pour se parler.
            </span>
          </div>
          <div className="list">
            <div className="item">
              <InputComponent
                placeholder="Prénom"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <InputComponent
                placeholder="Nom"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div className="item">
              <InputComponent
                type="email"
                disabled={true}
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="section">
          <div className="header">
            <p className="sub-title">Mot de passe</p>
            <span>
              Tu connais la chanson, un mot de passe sécurisé c'est important.
            </span>
          </div>
          <div className="list">
            <div className="item">
              <ButtonComponent text="Réinitialiser le mot de passe" />
            </div>
          </div>
        </div>
        <div className="section buttons">
          <ButtonComponent skin="secondy" text="Reset" onClick={handleReset} />
          <div className="ctn-btn">
            <ButtonComponent
              skin="secondy"
              text="Annuler"
              onClick={handleCancel}
            />
            <ButtonComponent
              text="Sauvegarder"
              onClick={handleSave}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default AccountSettings;
