import React, { useEffect, useMemo, useState } from "react";
import "./appearanceSettings.css";
import ButtonComponent from "../../../components/button";
import { app } from "../../../components/firebase/firebase";
import { useNavigate } from "react-router-dom";

import { HiOutlineUpload } from "react-icons/hi";

import ImageDefault from "../../../assets/images/bg-dock2.png";
import Notification from "../../../components/notification";

function AppearanceSettings() {
  const navigate = useNavigate();
  const db = app.firestore();
  const uid = sessionStorage.getItem("uid");

  const [reset, setReset] = useState(false);
  const [loading, setLoading] = useState(false);

  // notification useState
  const [msg, setMsg] = useState("");
  const [state, setState] = useState(null);

  const [activeIndex, setActiveIndex] = useState(0);
  const [imageUrl, setImageUrl] = useState(ImageDefault);
  const [file, setFile] = useState(null);

  const items = useMemo(
    () => ["1b60df", "9C209C", "D00100", "CFB701", "1D944F"],
    []
  );

  useEffect(() => {
    db.collection("users")
      .doc(uid)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const data = doc.data();
          setImageUrl(data.bg || ImageDefault);
          const color = data.color || items[0];
          setActiveIndex(items.indexOf(color));
        }
      });
  }, [db, reset, items, uid]);

  const handleClick = (index) => {
    setActiveIndex(index);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setFile(file);
    if (file) {
      const url = URL.createObjectURL(file);
      setImageUrl(url);
    }
  };

  const handleSave = () => {
    setLoading(true);
    if (file) {
      const storageRef = app.storage().ref();
      const fileRef = storageRef.child(`users/${uid}/bg.jpg`);
      fileRef.put(file).then(() => {
        fileRef
          .getDownloadURL()
          .then((url) => {
            db.collection("users")
              .doc(uid)
              .update({
                bg: url,
                color: items[activeIndex],
              })
              .then(() => {
                setFile(null);
                const img = new Image();
                img.src = imageUrl;
                img.onload = () => {
                  document.documentElement.style.setProperty(
                    "--bg-dock",
                    "url(" + url + ")"
                  );
                  document.documentElement.style.setProperty(
                    "--c-default",
                    "#" + items[activeIndex]
                  );
                  setLoading(false);
                  setMsg("Vos informations ont été mises à jour");
                  setState(true);
                };
              })
              .catch((error) => {
                console.log(error);
                setLoading(false);
                setMsg(
                  "Une erreur est survenue lors de la mise à jour de vos informations"
                );
                setState(false);
              });
          })
          .then(() => {
            setLoading(false);
            setMsg("Vos informations ont été mises à jour");
            setState(true);
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
            setMsg(
              "Une erreur est survenue lors de la mise à jour de vos informations"
            );
            setState(false);
          });
      });
    } else {
      db.collection("users")
        .doc(uid)
        .update({
          color: items[activeIndex],
        })
        .then(() => {
          document.documentElement.style.setProperty(
            "--c-default",
            "#" + items[activeIndex]
          );
          setLoading(false);
          setMsg("Vos informations ont été mises à jour");
          setState(true);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          setMsg(
            "Une erreur est survenue lors de la mise à jour de vos informations"
          );
          setState(false);
        });
    }
  };

  const handleCancel = () => {
    navigate("/dock");
  };
  return (
    <>
      <Notification
        msg={msg}
        state={state}
        setState={(value) => setState(value)}
        setMsg={(value) => setMsg(value)}
      />
      <div className="AppearanceSettings window">
        <div className="section">
          <h4>Apparence</h4>
          <p>Changer l'interface pour qu'elle vous ressemble au mieux.</p>
        </div>
        <div className="section">
          <div className="header">
            <p className="sub-title">
              Couleur <span>Bêta</span>
            </p>
            <span>
              Changer la couleur de l'interface, bouton, bordure, etc.
            </span>
          </div>
          <div className="radio">
            {items.map((item, index) => (
              <div
                key={index}
                className={activeIndex === index ? "item active" : "item"}
                style={{
                  backgroundColor: `#${item}`,
                  outline:
                    activeIndex === index ? "2px solid #" + item : "none",
                }}
                onClick={() => handleClick(index)}
              ></div>
            ))}
          </div>
        </div>
        <div className="section">
          <div className="header">
            <p className="sub-title">Fond d'écran</p>
            <span>
              Changer l'image de fond pour une meilleure personnalisation.
            </span>
          </div>
          <div className="input-bg">
            <img src={imageUrl} alt="" className="bg" />
            <input
              type="file"
              accept="image/*"
              onChange={(e) => handleImageChange(e)}
              id="bgSettings"
            />
            <label className="upload" htmlFor="bgSettings">
              <HiOutlineUpload className="icon" />
            </label>
          </div>
        </div>
        <div className="section buttons">
          <ButtonComponent
            skin="secondy"
            text="Reset"
            onClick={() => setReset(!reset)}
          />
          <div className="ctn-btn">
            <ButtonComponent
              skin="secondy"
              text="Annuler"
              onClick={() => handleCancel()}
            />
            <ButtonComponent
              text="Sauvegarder"
              onClick={() => handleSave()}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default AppearanceSettings;
