import React, { useState } from "react";
import "./inputComponent.css";

function SelectInput({
  label,
  type,
  width,
  onChange,
  children,
  id,
  maxLength,
  value,
  disabled,
}) {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="InputComponents SelectInput" style={{ width: width }}>
      <label className="text-sm" onClick={() => setIsActive(true)}>
        {label}
      </label>
      <label
        className={`InputComponents-container ${isActive ? "active" : ""}`}
        onClick={() => setIsActive(true)}
      >
        <select
          id={id}
          disabled={disabled}
          onChange={onChange}
          maxLength={maxLength}
          onFocus={() => {
            setIsActive(true);
          }}
          onBlur={() => {
            setIsActive(false);
          }}
        >
          {children}
        </select>
      </label>
    </div>
  );
}

export default SelectInput;
