import React, { useEffect } from "react";
import Cookies from "js-cookie";
import "./auth.css";

import LogoUser from "../../assets/images/logouser.jpeg";

const pathApi = "https://apiv2-6yhl3kiv2a-uc.a.run.app";

function Auth() {
  const [acces, setAcces] = React.useState(false);
  const [name, setName] = React.useState("");

  useEffect(() => {
    fetchProtectedData().then((data) => {
      if (data) {
        setName(data.displayName);
        setAcces(true);
        sessionStorage.setItem("name", data.displayName);
        sessionStorage.setItem("email", data.email);
        sessionStorage.setItem("uid", data.uid);
        sessionStorage.setItem("photoURL", data.photoURL);
        console.log(data.uid);
      }
    });
  }, []);

  return (
    <div className={`Auth ${acces ? "active" : ""}`}>
      <div>
        <div className="Auth-inner">
          <img src={LogoUser} alt="Logo user" />
          <p>Connecté en tant que {name}</p>
        </div>
      </div>
    </div>
  );
}

export default Auth;

const fetchProtectedData = async () => {
  try {
    const response = await fetch(
      pathApi + "/protected?sessionCookie=" + Cookies.get("session"),
      {
        method: "GET",
        credentials: "include", // Inclure les cookies dans la requête
      }
    );

    if (!response.ok) {
      // Si la réponse n'est pas OK, on gère l'erreur
      if (response.status === 401) {
        console.log("You are not authorized. Redirecting to login...");
      } else {
        console.log("Error fetching protected data:", response);
      }
    } else {
      const data = await response.json(); // Récupérer les données de la réponse
      return data;
    }
  } catch (error) {
    console.error("Error fetching protected data:", error);
  }
};

export { fetchProtectedData };
