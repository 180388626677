import React from "react";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";

// css
import "./App.css";
import "./assets/css/reset.css";
import "./assets/css/root.css";

// components
import Landing from "./pages/landing/index.jsx";
import Nav from "./components/bar/nav.jsx";
import Footer from "./components/footer/index.jsx";
// import Logs from "./components/function/logs.jsx";
import Dock from "./pages/dock/index.jsx";
import Settings from "./pages/dock/settings/index.jsx";
import DockBar from "./components/bar/dock/index.jsx";
import News from "./pages/dock/news/index.jsx";
import Short from "./pages/dock/short/index.jsx";
import Qr from "./pages/dock/qr/index.jsx";
import Vishort from "./pages/dock/vishort/index.jsx";
import Redirector from "./components/function/redirector.jsx";
import Image from "./pages/dock/image/index.jsx";
import Notifications from "./pages/dock/notification/index.jsx";

function App() {
  const router = createBrowserRouter([
    {
      // errorElement: <ErrorPage />,
      path: "/",
      element: [
        <Nav key="nav" />,
        <Outlet key="outlet" />,
        <Footer key="footer" />,
      ],
      children: [
        {
          path: "",
          element: <Landing />,
        },
      ],
    },
    {
      path: "/",
      children: [
        {
          path: "url/:code",
          element: <Redirector />,
        },
      ],
    },
    {
      // errorElement: <ErrorPage />,
      path: "/dock",
      element: [<DockBar key="DockBar" />, <Outlet key="Outlet" />],
      children: [
        {
          path: "",
          element: <Dock />,
        },
        {
          path: "settings",
          element: <Settings />,
        },
        {
          path: "notification",
          element: <Notifications />,
        },
        {
          path: "news",
          element: <News />,
        },
        {
          path: "short",
          element: <Short />,
        },
        {
          path: "qr",
          element: <Qr />,
        },
        {
          path: "vishort",
          element: <Vishort />,
        },
        {
          path: "image",
          element: <Image />,
        },
      ],
    },
  ]);

  // useEffect(() => {
  //   try {
  //     Logs({
  //       title: "Visited",
  //       text: "App component loaded",
  //       type: "info",
  //       data: "",
  //     });
  //   } catch (e) {
  //     console.log("error" + e);
  //   }
  // });

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
